import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import MainLayout from '../components/layouts/MainLayout'
import Footer from '../components/footer'
import SEO from '../components/seo'

// import HomeMp4 from '../videos/home.mp4'
// import HomeWebm from '../videos/home.webm'

import './index.css'

function IndexPage() {
  const data = useStaticQuery(graphql`
    {
      lights: file(relativePath: { eq: "home_lights.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      drawing: file(relativePath: { eq: "home_drawing.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <MainLayout activeMenu="home">
      <SEO title="Accueil" />
      <div className="home-container">
        <GatsbyImage
          image={data.lights.childImageSharp.gatsbyImageData}
          className="home-lights"
          alt="Petits lampions"
        />
        <div className="home-content">
          <GatsbyImage
            image={data.logo.childImageSharp.gatsbyImageData}
            className="home-logo"
            alt="Logo Caro + Yohann"
          />
          <video
            autoPlay
            loop
            muted
            playsInline
            className="home-drawing"
            poster="/loading.png"
          >
            <source src="/videos/home_4.webm" type="video/webm" />
            <source src="/videos/home_4.mp4" type="video/mp4" />
          </video>
        </div>
        <Footer>{'Made with <3 by Kakine & Yomansk8'}</Footer>
      </div>
    </MainLayout>
  )
}

export default IndexPage
